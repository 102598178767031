/* Imports */

@font-face {
    font-family: 'ofont';
    src: url(assets/fonts/next.ttf);
}

/* General web */

html {
    scroll-behavior: smooth;
}

html, body {
    height:100%;
}

svg {
    display: block;
    transition: all 0.6s 2s ease-in-out;
}
body,textarea {
    font-family: 'Poppins', sans-serif;
    margin:0;
}

*, ::after, ::before {
    box-sizing: border-box;
}

*:focus {
    outline: none !important;
    border: 0 !important;
}

a {
    color:white;
    text-decoration: none;
    transition: all 0.6s ease-in-out;
}

a:hover {
    color: #9ae7ff;
    text-decoration: none;
    transition: all 0.6s ease-in-out;
}

* {
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

img {
    max-width: 100%;
}

/* General classes */

.mt-auto {
    margin-top:auto;
}

.mb-auto {
    margin-bottom:auto;
}

.ml-auto {
    margin-left:auto;
}

.mr-auto {
    margin-right:auto;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-white {
    color:white;
}

.text-black {
    color:#202020;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    right:0%;
}

.btn {
    position:relative;
    padding:2%;
    font-size: 1.5em;
}

.btn:hover {
    cursor:pointer;
}

.btn-outline-white {
    background-color: rgba(0,0,0,0);
    color:white;
    border: solid 3px white;
    transition: all 0.4s ease-in-out;
}

.btn-outline-white:hover,.btn-outline-white.active {
    background-color:white;
    color:#202020;
    border: solid 3px white;
    transform: translateY(-5px);
    transition: all 0.4s ease-in-out;
}

.btn-outline-black {
    background-color: rgba(0,0,0,0);
    color:#202020;
    border: solid 3px #202020;
    transition: all 0.4s ease-in-out;
}

.btn-outline-black:hover,.btn-outline-black.active {
    background-color:#202020;
    color:white;
    border: solid 3px #202020;
    transition: all 0.4s ease-in-out;
    transform: translateY(-5px);
}

.w-50 {
    width:50% !important;
    transition: all 0.4s ease-in-out;
}

.w-30 {
    width:30% !important;
    transition: all 0.4s ease-in-out;
}

.w-100 {
    width: 100% !important;
    transition: all 0.4s ease-in-out;
}

.w-80 {
    width:80% !important;
    transition: all 0.4s ease-in-out;
}

.m-2 {
    margin:2%;
}

.p-2 {
    padding:2%;
}

.zi-999 {
    z-index:999;
}

.zi-2 {
    z-index: 2;
}

.mt--200 {
    margin-top:-200px;
}

.mt--300 {
    margin-top:-300px;
}

.mt--400 {
    margin-top:-400px;
}

.mt--900 {
    margin-top:-900px;
}

.mt-200 {
    margin-top:200px;
}

.mt-300 {
    margin-top:300px;
}

.ib {
    display: inline-block !important;
}

.shadow {
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    transition: all 0.4s ease-in-out;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex-grow: 1;
    -webkit-box-flex: 1;
    padding:2%;
    transition: all 0.4s ease-in-out;
}

.col-2 {
    width:50%;
    transition: all 0.4s ease-in-out;
}

.col-3 {
    width:33.33%;
    transition: all 0.4s ease-in-out;
}

.col-4 {
    width:20%;
    transition: all 0.4s ease-in-out;
}

.col-np {
    padding:0% !important;
}

.ofont {
    font-family: 'ofont';
    font-size: 4em;
    font-weight: 100;
}

.ofont-small {
    font-size: 2em;
}

.shrink {
    transform: scale(1);
    transition: all 0.8s ease-in-out;
}

.shrink:hover {
    transform: scale(0.9);
    transition: all 0.8s ease-in-out;
}

.separator-h {
    position: relative;
    width: 100%;
    height: 10vh;
}

.bg-primary {
    background-color:#9ae7ff;
}

.bg-secondary {
    background-color:#922c94;
}

.bg-white {
    background-color:white;
}

.bg-black {
    background-color:#202020;
}

.minh-100 {
    min-height: 100vh;
}

.minh-80 {
    min-height: 80vh;
}

.size-1 {
    font-size:6em;
    line-height: 200%;
}

.light {
    font-weight: lighter;
}

.logo {
    width:200px;
}

.card {
    background-color:white;
    padding:2%;
    border-radius: 10px;
    transition: all 0.8s ease-in-out;
}

.card:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
    transition: all 0.8s ease-in-out;
}

.bg {
    position:absolute;
    background-image: url("assets/img/bg.png");
    background-position: 0px;
    height:140vh;
    width:100%;
    top:0%;
    left:0%;
    z-index:1;
    animation: move 40s linear infinite;
    background-attachment: fixed;
    filter: brightness(10);
    opacity:0.06;
}

.o-02 {
    opacity: 0.2 !important;
}

/* Animations & Other */

.anim1 {
    animation: anim1 8s infinite ease-in-out;
}

.anim2 {
    animation: anim2 8s infinite ease-in-out;
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes anim1 {
    0%,100% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(-3%);
    }
}

@keyframes anim2 {
    0%,100% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-3%);
    }
}

@keyframes move {
    from {
        background-position-x: 0px;
        background-position-y: 0px;
    }
    to {
        background-position-x: 2000px;
        background-position-y: 2000px;
    }
}

::-webkit-scrollbar {
    width: 5px;
}
  
::-webkit-scrollbar-track {
    background: white; 
}

::-webkit-scrollbar-thumb {
    background: #9ae7ff; 
}
  
::-webkit-scrollbar-thumb:hover {
    background: #9ae7ff; 
}

/* Mobile */

.mobile-show {
    display: none;
}

@media (max-width: 992px) {

    .mobile-show {
        display:block;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-center {
        text-align: center;
    }

    .mobile-width {
        width:100%;
    }

    .size-1 {
        font-size:2.4em;
    }

    .bg {
        height:100vh;
    }

    .mobile-nm {
        margin:0;
    }

}